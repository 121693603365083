html, body {
  min-height: 100%;
  height: 1px;
}

body {
  font-family: 'Lato', sans-serif!important;
  background: #e5e5e5;
  color: var(--text)!important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  height: 1px;
}
